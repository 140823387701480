export const ROUTE_HOST_DASHBOARD = 'hostDashboard';
export const ROUTE_HOST_HOME = 'hostHome';
export const ROUTE_HOST_NOTIFICATIONS = 'hostNotification';
export const ROUTE_HOST_ORGANIZATION = 'hostOrganization';
export const ROUTE_HOST_PROFILE = 'hostProfile';
export const ROUTE_HOST_GENERATE_AD = 'hostGenerateAd';
export const ROUTE_HOST_CAMPAIGNS = 'hostCampaigns';
export const ROUTE_HOST_CAMPAIGN_DETAILS = 'hostCampaignDetails';
export const ROUTE_HOST_SETUP = 'hostSetup';
export const ROUTE_HOST_VALIDATE_EMAIL = 'validateEmail';

export const ROUTE_ADVERTISER_DASHBOARD = 'advertiserDashboard';
export const ROUTE_ADVERTISER_CAMPAIGNS = 'advertiserCampaigns';
export const ROUTE_ADVERTISER_CAMPAIGN_DETAILS = 'advertiserCampaignDetails';
export const ROUTE_ADVERTISER_ORGANIZATION = 'advertiserOrganization';
export const ROUTE_ADVERTISER_PROFILE = 'advertiserProfile';
export const ROUTE_ADVERTISER_SETUP = 'advertiserSetup';

export const ROUTE_LOGOUT = 'logout';
export const ROUTE_LOGIN = 'login';
export const ROUTE_SIGN_UP = 'signUp';
export const ROUTE_UNAUTHORIZED = 'unauthorized';
export const ROUTE_ERROR = 'error';
export const ROUTE_NOT_FOUND = 'notFound';
