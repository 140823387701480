<template>
  <Dialog
    :visible="visible"
    :dismissableMask="!isSubmitting"
    modal
    :header="header"
    @update:visible="onClose"
    :pt="{
      closeButton: {
        disabled: isSubmitting,
      },
      content: {
        style: {
          maxWidth: '400px',
        },
      },
    }"
  >
    <div>
      <slot>
        <p>
          Are you sure you want to perform this action?
        </p>
      </slot>
    </div>

    <template #footer>
      <div class="flex justify-content-end">
        <Button
          text
          plain
          :label="cancelButtonLabel"
          :disabled="isSubmitting"
          @click="$emit('update:visible', false)"
        />
        <Button
          class="ml-2"
          :label="confirmButtonLabel"
          :loading="isSubmitting"
          :disabled="confirmButtonDisabled"
          @click="$emit('confirm')"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: 'Confirm',
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    confirmButtonLabel: {
      type: String,
      default: 'Confirm',
    },
    cancelButtonLabel: {
      type: String,
      default: 'Cancel',
    },
    confirmButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose(event) {
      if (this.isSubmitting) return;

      this.$emit('update:visible', event);
    },
  },
};
</script>
