import ekozAxios from './instance/axios';

export const readInvoices = ({
  organizationId,
}) => ekozAxios.request({
  method: 'get',
  url: '/billing/get_invoices.php',
  params: {
    org_id: organizationId,
  },
});
