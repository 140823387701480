import { defineStore } from 'pinia';
import * as api from '@/api';
import _uniqBy from 'lodash/uniqBy';

export const useInvoicesStore = defineStore('invoices', {
  state: () => ({
    invoices: [],
    invoicesAreLoading: false,
  }),
  actions: {
    async getInvoices({ organizationId } = {}) {
      try {
        this.invoicesAreLoading = true;

        const res = await api.readInvoices({ organizationId });

        const invoices = res.data.length > 0
          ? _uniqBy(res.data[0].invoices, 'invoice_id')
          : [];

        this.invoices = invoices;
      } finally {
        this.invoicesAreLoading = false;
      }
    },
  },
});
