<template>
  <Dialog
    class="organization-invoices-dialog"
    :visible="visible"
    dismissableMask
    modal
    @update:visible="$emit('update:visible', $event)"
    header="Invoices"
    :pt="{
      header: {
        class: 'bg-gray-100',
      },
      content: {
        class: 'bg-gray-100',
      },
      footer: {
        class: 'bg-gray-100',
      },
    }"
  >
    <template v-if="invoicesAreLoading">
      <Card
        v-for="index in 2"
        :key="index"
        class="mb-2"
      >
        <template #content>
          <div class="line-height-3 mb-3">
            <div class="flex align-items-center mb-2">
              <Skeleton width="94px" height="21px" />
              <Skeleton class="ml-2" width="53px" height="24px" />
            </div>
            <Skeleton width="120px" height="34px" />
          </div>
          <div class="flex justify-content-end">
            <Skeleton width="85px" height="33px" />
          </div>
        </template>
      </Card>
    </template>

    <template v-else>
      <p>
        Have any questions about invoices? Contact
        <a
          class="text-primary"
          :href="mailTo"
        >
          {{ SUPPORT_EMAIL }}
          <i class="text-sm pi pi-external-link" />
        </a>
      </p>
      <p
        v-if="formattedInvoices.length === 0"
        class="mt-0">
        No Invoices found.
      </p>

      <div v-else>
        <Card
          class="mb-2"
          v-for="invoice in formattedInvoices"
          :key="invoice.invoice_id"
        >
          <template #content>
            <div class="line-height-3">
                <span class="mr-2 text-gray-500 text-lg">
                  {{ invoice.formattedDate }}
                </span>
                <Chip
                  :class="['capitalize text-sm', invoice.statusChipStyle]"
                  :label="invoice.status"
                />
                <br />
                <span class="font-bold text-3xl">
                  {{ invoice.formattedAmount }}
                </span>
            </div>
          </template>
          <template #footer>
            <div class="flex justify-content-end">
              <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
              <a
                v-if="invoice.hosted_invoice_url"
                :href="invoice.hosted_invoice_url"
                target="_blank"
              >
                <Button
                  size="small"
                  label="View"
                  icon="pi pi-external-link"
                />
              </a>
            </div>
          </template>
        </Card>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-content-end">
        <Button
          text
          plain
          label="Close"
          @click="$emit('update:visible', false)"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { DateTime } from 'luxon';
import { mapStores } from 'pinia';
import { useMyUserStore } from '@/stores';
import { SUPPORT_EMAIL } from '@/constants';
import { generateBodyText } from '@/utils/support';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    invoices: {
      type: Array,
      default: () => ([]),
    },
    invoicesAreLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useMyUserStore),
    formattedInvoices() {
      return this.invoices.map((item) => {
        const formattedAmount = item.amount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'USD',
        }).toString();

        const formattedDate = DateTime.fromISO(item.date)
          .toLocaleString(DateTime.DATE_SHORT);

        const statusChipStyle = item.status === 'paid'
          ? 'bg-light-green text-green-500'
          : '';

        return {
          ...item,
          formattedAmount,
          statusChipStyle,
          formattedDate,
        };
      });
    },
    mailTo() {
      const subject = 'Ekoz.ai Invoice Question';

      const body = generateBodyText({
        userId: this.myUserStore.userId,
        url: window.location.href,
      });

      return `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;
    },
  },
  data() {
    return {
      SUPPORT_EMAIL,
    };
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.organization-invoices-dialog {
  width: 300px;

  @media screen and (
    min-width: #{map-get($breakpoints, 'sm')}
  ) {
    width: 500px;
  }
}
</style>

<style lang="scss" scoped>
.bg-light-green {
  background-color: #f4fdf7;
}
</style>
