import { createRouter, createWebHistory } from 'vue-router';

import HostDashboardLayout from '@/views/host/dashboardLayout';
import HostOrganization from '@/views/host/organization';
import HostProfile from '@/views/host/profile';
import GenerateAd from '@/views/host/generateAd';
import HostCampaigns from '@/views/host/campaigns';
import HostCampaignDetails from '@/views/host/campaignDetails';
import HostSetup from '@/views/host/setup';
import ValidateEmail from '@/views/host/validateEmail';

import AdvertiserDashboardLayout from '@/views/advertiser/dashboardLayout';
import AdvertiserCampaigns from '@/views/advertiser/campaigns';
import AdvertiserCampaignDetails from '@/views/advertiser/campaignDetails';
import AdvertiserProfile from '@/views/advertiser/profile';
import AdvertiserOrganization from '@/views/advertiser/organization';
import AdvertiserSetup from '@/views/advertiser/setup';

import Login from '@/views/login';
import Logout from '@/views/logout';
import SignUp from '@/views/signUp';
import Error from '@/views/error';
import Notifications from '@/views/notifications';

import {
  authAdvertiserGuard,
  authHostGuard,
  beforeEnterAdvertiserSetupGuard,
  beforeEnterHostOrganizationGuard,
  beforeEnterHostSetupGuard,
  publicGuard,
} from './guards';
import {
  ROUTE_ADVERTISER_CAMPAIGNS,
  ROUTE_ADVERTISER_DASHBOARD,
  ROUTE_ERROR,
  ROUTE_HOST_GENERATE_AD,
  ROUTE_HOST_CAMPAIGNS,
  ROUTE_HOST_CAMPAIGN_DETAILS,
  ROUTE_HOST_DASHBOARD,
  ROUTE_HOST_HOME,
  ROUTE_HOST_NOTIFICATIONS,
  ROUTE_HOST_ORGANIZATION,
  ROUTE_HOST_PROFILE,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_NOT_FOUND,
  ROUTE_SIGN_UP,
  ROUTE_UNAUTHORIZED,
  ROUTE_ADVERTISER_PROFILE,
  ROUTE_ADVERTISER_ORGANIZATION,
  ROUTE_ADVERTISER_CAMPAIGN_DETAILS,
  ROUTE_HOST_SETUP,
  ROUTE_ADVERTISER_SETUP,
  ROUTE_HOST_VALIDATE_EMAIL,
} from './routes';

const routes = [
  // host users
  {
    path: '/',
    name: ROUTE_HOST_DASHBOARD,
    component: HostDashboardLayout,
    beforeEnter: authHostGuard,
    redirect: { name: ROUTE_HOST_HOME },
    children: [
      {
        path: '',
        name: ROUTE_HOST_HOME,
        component: HostCampaigns,
      },
      {
        path: 'setup',
        name: ROUTE_HOST_SETUP,
        component: HostSetup,
        beforeEnter: beforeEnterHostSetupGuard,
      },
      {
        path: 'notifications',
        name: ROUTE_HOST_NOTIFICATIONS,
        component: Notifications,
      },
      {
        path: 'organization',
        name: ROUTE_HOST_ORGANIZATION,
        component: HostOrganization,
        beforeEnter: beforeEnterHostOrganizationGuard,
      },
      {
        path: 'profile',
        name: ROUTE_HOST_PROFILE,
        component: HostProfile,
      },
      {
        path: 'generate-ad',
        name: ROUTE_HOST_GENERATE_AD,
        component: GenerateAd,
      },
      {
        path: 'campaigns',
        name: ROUTE_HOST_CAMPAIGNS,
        component: HostCampaigns,
      },
      {
        path: 'campaigns/:adId',
        name: ROUTE_HOST_CAMPAIGN_DETAILS,
        component: HostCampaignDetails,
      },
    ],
  },
  // advertiser users
  {
    path: '/advertiser',
    name: ROUTE_ADVERTISER_DASHBOARD,
    component: AdvertiserDashboardLayout,
    beforeEnter: authAdvertiserGuard,
    redirect: { name: ROUTE_ADVERTISER_CAMPAIGNS },
    children: [
      {
        path: 'campaigns',
        name: ROUTE_ADVERTISER_CAMPAIGNS,
        component: AdvertiserCampaigns,
      },
      {
        path: 'campaigns/:campaignId',
        name: ROUTE_ADVERTISER_CAMPAIGN_DETAILS,
        component: AdvertiserCampaignDetails,
      },
      {
        path: 'profile',
        name: ROUTE_ADVERTISER_PROFILE,
        component: AdvertiserProfile,
      },
      {
        path: 'organization',
        name: ROUTE_ADVERTISER_ORGANIZATION,
        component: AdvertiserOrganization,
      },
      {
        path: 'setup',
        name: ROUTE_ADVERTISER_SETUP,
        component: AdvertiserSetup,
        beforeEnter: beforeEnterAdvertiserSetupGuard,
      },
    ],
  },

  {
    path: '/validate-email',
    name: ROUTE_HOST_VALIDATE_EMAIL,
    component: ValidateEmail,
  },
  {
    path: '/logout',
    name: ROUTE_LOGOUT,
    component: Logout,
  },
  {
    path: '/login',
    name: ROUTE_LOGIN,
    component: Login,
    beforeEnter: publicGuard,
  },
  {
    path: '/sign-up',
    name: ROUTE_SIGN_UP,
    component: SignUp,
    beforeEnter: publicGuard,
  },
  {
    path: '/unauthorized',
    name: ROUTE_UNAUTHORIZED,
    component: Error,
    props: {
      errorCode: 403,
      errorText: 'Unauthorized',
    },
  },
  {
    path: '/error',
    name: ROUTE_ERROR,
    component: Error,
  },
  {
    path: '/:pathMatch(.*)*',
    name: ROUTE_NOT_FOUND,
    component: Error,
    props: {
      errorCode: 404,
      errorText: 'Page Not Found',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
