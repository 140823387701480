import _get from 'lodash/get';

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

// this validation is the same that yup uses
export const validateEmail = (email) => (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/).test(email);

export const isJsonString = (value) => {
  try {
    JSON.parse(value);
  } catch {
    return false;
  }
  return true;
};

/**
 * Get value from data based on path. This also validates the value is of a certain type.
 * If is not of the correct type, the default value is used. This is important when using the
 * scrapeProgramMetadata endpoint because it will sometimes
 * return an empty array when you would expect an empty string
 *
 * @param {object} data
 * @param {string} path
 * @param {mixed} valueDefault
 * @param {string} valueType typeof value ('string', 'number', 'object', 'undefined')
 * @returns {mixed}
 */
export const getSanitizedValue = (data, path, valueDefault, valueType) => {
  const value = _get(data, path, valueDefault);

  // eslint-disable-next-line valid-typeof
  return typeof value === valueType
    ? value
    : valueDefault;
};

const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');

    if (!successful) {
      throw new Error('Error copying to clipboard in fallback function');
    }
  } finally {
    document.body.removeChild(textArea);
  }
};

export const copyTextToClipboard = async (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  await navigator.clipboard.writeText(text);
};

export const exportToCsv = (filename, keys, rows) => {
  const separator = ',';
  const csvContent = `${keys.join(separator)
  }\n${
    rows.map((row) => keys.map((k) => {
      const cell = row[k] === null || row[k] === undefined ? '' : row[k];

      return cell;
    }).join(separator)).join('\n')}`;

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
