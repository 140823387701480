<template>
  <div>
    <PageContainer>
      <div v-if="!myUserStore.voiceId">
        You do not have a Voiceprint Eko setup. Visit the
        <router-link
          :to="{ name: ROUTE_HOST_PROFILE }"
        >
          <Button
            class="p-0"
            link
            label="profile"
          />
        </router-link>
        page to set up one.
      </div>

      <div v-else>
        <GenerateDocumentAdCard />

        <div class="mt-4">
          <GenerateTextAdCard />
        </div>
      </div>
    </PageContainer>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useMyUserStore } from '@/stores';
import { ROUTE_HOST_PROFILE } from '@/router/routes';
import GenerateTextAdCard from './components/generateTextAdCard';
import GenerateDocumentAdCard from './components/generateDocumentAdCard';

export default {
  computed: {
    ...mapStores(useMyUserStore),
  },
  components: {
    GenerateTextAdCard,
    GenerateDocumentAdCard,
  },
  data() {
    return {
      ROUTE_HOST_PROFILE,
    };
  },
};
</script>
