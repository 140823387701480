<template>
  <VeeField
    :name="fieldName"
    :modelValue="modelValue"
    v-slot="{ errors }"
  >
    <div
      :class="{
        'mb-2 font-bold': true,
        'text-red-400': errors.length > 0,
      }"
    >
      {{  fieldLabel }}
    </div>
    <small class="inline-block mb-2">
      {{ helperText }}
    </small>
    <div class="flex align-items-center">
      <span
        :class="{
          'text-red-400': errors.length > 0
        }"
      >
        {{ modelValue.length }} selected
      </span>

      <Button
        class="ml-2"
        label="Select Categories"
        outlined
        @click="onClickSelectCategories"
      />
    </div>
    <small
      v-if="errors.length > 0"
      class="text-red-400 inline-block mt-2"
    >
      {{ errors[0] }}
    </small>
  </VeeField>
  <Sidebar
    v-model:visible="isSidebarVisible"
    class="w-full sm:w-40rem"
    position="right"
    header="Select Categories"
    :pt="{
      content: {
        class: 'flex flex-column'
      }
    }"
  >
    <div class="flex flex-column overflow-y-auto overflow-x-hidden flex-grow-1">
      <small
        class="inline-block mb-3"
        v-if="helperText"
      >
        {{ helperText }}
      </small>

      <InputGroup class="mb-3">
        <InputGroupAddon>
          <i class="pi pi-search" />
        </InputGroupAddon>
        <InputText
          v-model="searchValue"
          type="text"
          placeholder="Search categories"
        />
        <Button
          v-if="searchValue !== ''"
          icon="pi pi-times"
          severity="secondary"
          @click="searchValue = ''"
        />
      </InputGroup>

      <div>
        <Button
          v-if="!disableSelectAll"
          label="Select all"
          size="small"
          text
          :onClick="onClickSelectAll"
        />
        <Button
          class="ml-2"
          :label="`Clear all (${clearAllCount})`"
          :disabled="selectedCategories.length === 0"
          size="small"
          text
          :onClick="onClickClearAll"
        />
      </div>

      <div class="mt-3">
        <div class="grid">
          <div
            v-for="column in formattedCategoryOptions"
            :key="column.id"
            class="col-12 sm:col-6 py-0"
          >
            <div
              class="mt-2"
              v-for="item in column.categories"
              :key="item.value"
            >
              <Checkbox
                v-model="selectedCategories"
                :value="item.value"
                :inputId="`category-${item.value}`"
              />
              <label
                class="ml-2"
                :for="`category-${item.value}`"
                :class="{
                  'font-bold': item.bold,
                }"
              >
                {{ item.label }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-content-end pt-2">
      <Button
        label="Cancel"
        text
        plain
        @click="isSidebarVisible = false"
      />
      <Button
        :label="`Submit (${selectedCount})`"
        @click="onSubmit"
      />
    </div>
  </Sidebar>
</template>

<script>
import _uniqBy from 'lodash/uniqBy';

export default {
  props: {
    categoryOptions: {
      type: Array,
      required: true,
    },
    fieldLabel: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    helperText: {
      type: String,
    },
    disableSelectAll: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // format categories into 2 columns
    formattedCategoryOptions() {
      const halfwayIndex = Math.ceil(this.filteredCategoryOptions.length / 2);

      return [
        {
          id: 0,
          categories: this.filteredCategoryOptions.slice(0, halfwayIndex),
        },
        {
          id: 1,
          categories: this.filteredCategoryOptions.slice(halfwayIndex),
        },
      ];
    },
    filteredCategoryOptions() {
      if (this.searchValue === '') {
        return this.categoryOptions;
      }

      return this.categoryOptions.filter(
        ({ label }) => typeof label === 'string'
          && label.toLowerCase().indexOf(this.searchValue) !== -1,
      );
    },
    clearAllCount() {
      return this.filteredCategoryOptions.filter(
        (item) => this.selectedCategories.some((value) => value === item.value),
      ).length;
    },
    selectedCount() {
      return this.selectedCategories.length;
    },
  },
  data() {
    return {
      isSidebarVisible: false,
      // local copy of selected categories
      // this allows user to make changes before submitting
      selectedCategories: [],
      searchValue: '',
    };
  },
  methods: {
    onClickSelectCategories() {
      this.selectedCategories = [...this.modelValue];
      this.isSidebarVisible = true;
    },
    onSubmit() {
      this.$emit('update:modelValue', this.selectedCategories);
      this.isSidebarVisible = false;
    },
    onClickSelectAll() {
      this.selectedCategories = _uniqBy([
        ...this.selectedCategories,
        ...this.filteredCategoryOptions.map((option) => option.value),
      ]);
    },
    onClickClearAll() {
      this.selectedCategories = this.selectedCategories.filter((value) => (
        !this.filteredCategoryOptions.some((item) => item.value === value)
      ));
    },
  },
};
</script>
