<template>
  <PageContainer maxWidth="600px">
    <PageTitle>
      Email Validation
    </PageTitle>
    <Message
      v-if="error"
      class="m-0"
      severity="error"
      icon="pi pi-exclamation-triangle"
      :closable="false"
      :pt="{
        icon: {
          class: 'text-xl align-self-start',
        },
        text: {
          class: 'font-normal text-sm',
        },
      }"
    >
      <p class="mt-0">
        <strong>Error:</strong> {{ error }}
      </p>
      <p class="mb-0">
        Return to
        <router-link
          :to="{
            name: ROUTE_HOST_CAMPAIGNS,
          }"
        >
          dashboard
        </router-link>
      </p>
    </Message>
    <Message
      v-if="successMessage"
      class="m-0"
      severity="success"
      :closable="false"
      :pt="{
        icon: {
          class: 'text-xl align-self-start',
        },
        text: {
          class: 'font-normal text-sm',
        },
      }"
    >
      <p class="mt-0">
        <strong>Success:</strong> {{ successMessage }}
      </p>
      <p class="mb-0">
        Return to
        <router-link
          :to="{
            name: ROUTE_HOST_CAMPAIGNS,
          }"
        >
          dashboard
        </router-link>
      </p>
    </Message>
  </PageContainer>
</template>

<script>
import { ROUTE_HOST_CAMPAIGNS } from '@/router/routes';
import * as api from '@/api';
import { parseMessageFromError } from '@/utils/errors';
import { SUPPORT_EMAIL } from '@/constants';

export default {
  data() {
    return {
      error: null,
      successMessage: null,
      isSubmitting: false,
      ROUTE_HOST_CAMPAIGNS,
    };
  },
  mounted() {
    this.validateEmail();
  },
  methods: {
    async validateEmail() {
      try {
        this.isSubmitting = true;

        const {
          code,
          email,
        } = this.$route.query;

        if (typeof code === 'undefined' || typeof email === 'undefined') {
          throw new Error('Parameters are missing. Please ensure your verification link is not modified.');
        }

        const res = await api.validateProgram({
          code,
          email,
        });

        if (!res || !res.success) {
          throw new Error(`Validating email was not successful. Please try again or contact ${SUPPORT_EMAIL}.`);
        }

        this.successMessage = 'Email validated';
      } catch (error) {
        const message = parseMessageFromError(error, 'Error validating email');

        this.error = message;
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
