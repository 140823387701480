<template>
  <section>
    <MenuBar
      class="menu-bar"
      :model="menuItems"
    >
      <template #start>
        <router-link
          class="inline-block px-2"
          to="/"
        >
          <img
            class="header-logo"
            :src="logo"
            alt="Ekoz.ai logo"
          />
        </router-link>
      </template>
      <template #item="{ item, props }">
        <router-link
          v-if="item.route"
          v-slot="{ href, navigate, isExactActive }"
          :to="item.route"
          custom
        >
            <a
              :class="{
                'text-primary': isExactActive,
              }"
              :href="href"
              v-bind="props.action"
              @click="navigate"
            >
                <span
                  v-if="item.icon"
                  class="mr-2"
                  :class="item.icon"
                />
                <span>{{ item.label }}</span>
            </a>
        </router-link>
        <a
          v-else-if="item.url"
          :href="item.url"
          :target="item.target"
          v-bind="props.action"
        >
            <span
              v-if="item.icon"
              class="mr-2"
              :class="item.icon"
            />
            <span>{{ item.label }}</span>
        </a>
        <Button
          v-else
          class="w-full"
          outlined
          size="small"
          :icon="item.icon || ''"
          :label="item.label"
          @click="item.command"
        />
      </template>
      <template #end>
        <div class="flex align-items-center">
          <!-- <Button
            text
            plain
            aria-haspopup="true"
            aria-controls="notifications-overlay"
            @click="(event) => this.$refs.notificationOverlay.toggle(event)"
          >
            <i
              class="pi pi-bell"
              v-badge.contrast="3"
            />
          </Button> -->
          <NotificationOverlay
            id="notification-overlay"
            ref="notificationOverlay"
          />
            <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
            <a
              :href="FAQ_LINK_HOST"
              target="_blank"
            >
            <Button
              class="px-2"
              text
              plain
              size="small"
            >
              <i class="pi pi-question-circle sm:hidden" />
              <span class="hidden sm:inline">FAQ</span>
            </Button>
          </a>
          <Button
            class="px-2"
            text
            plain
            size="small"
            @click="isContactUsDialogVisible = true"
          >
            <i class="pi pi-envelope sm:hidden" />
            <span class="hidden sm:inline">Support</span>
          </Button>
          <Button
            text
            plain
            aria-haspopup="true"
            aria-controls="profile-menu"
            @click="(event) => this.$refs.profileMenu.toggle(event)"
          >
            <Avatar
              :label="myUserStore.userInitials"
              shape="circle"
            />
            <i class="pi pi-angle-down ml-2" />
          </Button>
          <ProfileMenu
            id="profile-menu"
            ref="profileMenu"
          />
        </div>
      </template>
    </MenuBar>
    <router-view />
    <ManageVoiceprintDialog
      v-model:visible="isManageVoiceprintDialogVisible"
    />
    <ContactUsDialog
      v-model:visible="isContactUsDialogVisible"
    />
  </section>
</template>

<script>
import { mapStores } from 'pinia';
import {
  useMyUserStore,
  useCategoriesStore,
  useProgramsStore,
} from '@/stores';
import logo from '@/assets/logo.png';
import ContactUsDialog from '@/components/contactUsDialog';
import { FAQ_LINK_HOST } from '@/constants';
import ManageVoiceprintDialog from '@/components/manageVoiceprintDialog';
import { ROUTE_HOST_CAMPAIGNS, ROUTE_HOST_ORGANIZATION, ROUTE_HOST_PROFILE } from '@/router/routes';
import { parseMessageFromError } from '@/utils/errors';
import NotificationOverlay from './components/notificationOverlay';
import ProfileMenu from './components/profileMenu';

export default {
  components: {
    NotificationOverlay,
    ProfileMenu,
    ManageVoiceprintDialog,
    ContactUsDialog,
  },
  data() {
    return {
      FAQ_LINK_HOST,
      logo,
      menuItems: [
        {
          label: 'Campaigns',
          route: {
            name: ROUTE_HOST_CAMPAIGNS,
          },
        },
        {
          label: 'Organization',
          route: {
            name: ROUTE_HOST_ORGANIZATION,
          },
        },
        {
          label: 'Profile',
          route: {
            name: ROUTE_HOST_PROFILE,
          },
        },
        {
          label: 'Voiceprint Eko',
          icon: 'pi pi-microphone',
          command: () => {
            this.isManageVoiceprintDialogVisible = true;
          },
        },
      ],
      isManageVoiceprintDialogVisible: false,
      isContactUsDialogVisible: false,
    };
  },
  computed: {
    ...mapStores(useMyUserStore, useCategoriesStore, useProgramsStore),
  },
  async mounted() {
    try {
      await this.categoriesStore.getCategories();
    } catch (error) {
      const message = parseMessageFromError(error, 'Error loading categories.');

      this.$toast.add({
        severity: 'error',
        detail: message,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.header-logo {
  width: 80px;

  @media (min-width: 961px) {
    width: 120px;
  }
}

.menu-bar {
  border-width: 0 0 1px 0;
  border-radius: 0;

  :deep(.p-menubar-start) {
    order: 1;

    @media (min-width: 961px) {
      order: 0;
    }
  }
  :deep(.p-menubar-end) {
    order: 2;
  }
}
</style>
