<template>
  <section
    :style="{
      height: '100vh',
    }"
  >
    <div class="grid grid-nogutter h-full md:flex-row bg-white">
      <div class="col-12 md:col-5">
        <div
          class="pod-image"
          :style="{
            backgroundImage: `url(${rectLogo})`,
          }"
        >
        </div>
      </div>
      <div class="col-12 md:col-7 bg-white">
        <div class="flex justify-content-center md:pt-8 px-3">
          <div class="sign-up-form-container">
            <VeeForm
              v-slot="{ handleSubmit }"
              :validation-schema="schema"
              as="div"
              @invalidSubmit="onInvalidSubmit"
            >
              <form @submit.prevent="handleSubmit($event, onSubmit)">
                <h1 class="text-center text-4xl">{{ title }}</h1>
                <p
                  v-if="description"
                  v-html="description"
                />
                <BaseSelectButton
                  v-if="userCanChooseRole"
                  v-model="form.signupType"
                  fieldId="signupType"
                  fieldName="signupType"
                  :options="SIGNUP_TYPE_OPTIONS"
                  class="flex"
                  :pt="{
                    button: {
                      class: 'flex-grow-1',
                    },
                  }"
                  :allowEmpty="false"
                />
                <div  class="mt-4">
                  <BaseInput
                    v-model="form.email"
                    fieldId="email"
                    fieldName="email"
                    fieldLabel="Email"
                    type="email"
                    :disabled="emailIsPrefilled"
                  />
                </div>
                <div class="grid">
                  <div class="col-6">
                    <BaseInput
                      v-model="form.firstName"
                      fieldId="firstName"
                      fieldName="firstName"
                      fieldLabel="First Name"
                    />
                  </div>
                  <div class="col-6">
                    <BaseInput
                      v-model="form.lastName"
                      fieldId="lastName"
                      fieldName="lastName"
                      fieldLabel="Last Name"
                    />
                  </div>
                </div>
                <BasePassword
                  v-model="form.password"
                  fieldId="password"
                  fieldName="password"
                  fieldLabel="Password"
                  class="w-full"
                  inputClass="w-full"
                />
                <BasePassword
                  v-model="form.confirmPassword"
                  fieldId="confirmPassword"
                  fieldName="confirmPassword"
                  fieldLabel="Confirm Password"
                  class="w-full"
                  inputClass="w-full"
                  :feedback="false"
                />
                <BaseCheckbox
                  v-model="form.acceptedTerms"
                  binary
                  fieldId="acceptedTerms"
                  fieldName="acceptedTerms"
                  fieldLabel="Checking the box agrees to our Terms of Service"
                  :fieldContainerAttrs="{
                    class: 'px-2',
                  }"
                >
                  <template #fieldLabel>
                    Checking the box agrees to our
                    <a
                      class="font-bold"
                      :href="TERMS_OF_SERVICE_LINK"
                      target="_blank"
                    >
                      Terms of Service
                    </a>
                  </template>
                </BaseCheckbox>
                <Button
                  class="justify-content-center w-full"
                  type="submit"
                  :loading="isSubmitting"
                  label="Submit"
                />
              </form>
            </VeeForm>
            <p class="block text-center">
              Have an account?
              <router-link :to="{ name: ROUTE_LOGIN }">Sign in</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapStores } from 'pinia';
import {
  object,
  string,
  ref,
  bool,
} from 'yup';
import { useSettingsStore, useAuthStore, useRolesStore } from '@/stores';
import rectLogo from '@/assets/ekoz_rect.jpeg';
import * as api from '@/api';
import { INVALID_FORM_SUBMISSION_MESSAGE } from '@/utils/messages';
import { validateEmail } from '@/utils/helpers';
import { ROUTE_LOGIN } from '@/router/routes';
import { parseMessageFromError } from '@/utils/errors';
import { TERMS_OF_SERVICE_LINK } from '@/constants';

const SIGN_UP_TYPE_HOST = 'host';
const SIGN_UP_TYPE_ADVERTISER = 'advertiser';

const SIGNUP_TYPE_OPTIONS = [
  {
    label: 'Host',
    value: SIGN_UP_TYPE_HOST,
  },
  {
    label: 'Advertiser',
    value: SIGN_UP_TYPE_ADVERTISER,
  },
];

export default {
  computed: {
    ...mapStores(useSettingsStore, useAuthStore, useRolesStore),
    userCanChooseRole() {
      return !this.emailIsPrefilled;
    },
    title() {
      return this.inviteOrg
        ? 'Welcome'
        : 'Sign up';
    },
    description() {
      return this.inviteOrg
        ? `You have been invited to ${this.inviteOrg}. Complete the form to finish your user account setup.`
        : null;
    },
  },
  data() {
    return {
      ROUTE_LOGIN,
      schema: object({
        signupType: string().when([], {
          is: () => this.emailIsPrefilled,
          then: (schema) => schema,
          otherwise: (schema) => schema.required('User type is required'),
        }),
        email: string().email().required('Email is required'),
        firstName: string().required('First name is required'),
        password: string().required('Password is required'),
        confirmPassword: string().required('Confirm Passoword is required')
          .oneOf([ref('password'), ''], 'Passwords must match'),
        acceptedTerms: bool().oneOf([true], 'Terms of Service must be checked'),
      }),
      rectLogo,
      isSubmitting: false,
      form: {
        signupType: null,
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
        acceptedTerms: false,
      },
      emailIsPrefilled: false,
      inviteOrg: null,
      TERMS_OF_SERVICE_LINK,
      SIGNUP_TYPE_OPTIONS,
    };
  },
  mounted() {
    const { email, userType, inviteOrg } = this.$route.query;
    if (typeof email === 'string' && validateEmail(email)) {
      this.emailIsPrefilled = true;
      this.form.email = email;
    }
    if (typeof inviteOrg === 'string') {
      this.inviteOrg = inviteOrg;
    }

    /* eslint-disable-next-line no-console */
    this.rolesStore.getRoles().catch((error) => console.error('error loading roles', error));

    // prefill user type if available
    switch (userType) {
      case SIGN_UP_TYPE_HOST:
        this.form.signupType = SIGN_UP_TYPE_HOST;
        break;
      case SIGN_UP_TYPE_ADVERTISER:
        this.form.signupType = SIGN_UP_TYPE_ADVERTISER;
        break;
      default:
        // do nothing
    }
  },
  methods: {
    onInvalidSubmit() {
      this.$toast.add({
        severity: 'warn',
        detail: INVALID_FORM_SUBMISSION_MESSAGE,
      });
    },
    async onSubmit() {
      try {
        this.isSubmitting = true;

        const {
          email,
          password,
          firstName,
          lastName,
        } = this.form;

        const roleId = this.form.signupType === SIGN_UP_TYPE_ADVERTISER
          ? this.rolesStore.advertiserAdminRole.id
          : this.rolesStore.hostAdminRole.id;

        await api.signUp({
          email,
          password,
          firstName,
          lastName,
          roleId: this.userCanChooseRole
            ? roleId
            : undefined,
        });

        this.$toast.add({
          severity: 'success',
          detail: `Successfully registered to ${
            this.settingsStore.appName
          }. Please log in to continue.`,
        });
        this.$router.push({
          name: ROUTE_LOGIN,
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error signing up.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-up-form-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 400px;
}

.sign-up-tabs {
  :deep(.p-tabmenu-nav) {
    padding-left: 0;
  }
  :deep(.p-tabmenuitem) {
    flex: 1 1 0px;
  }
  :deep(.p-menuitem-link) {
    justify-content: center;
  }
}

.pod-image {
  height: 140px;
  width: 100%;
  background-size: cover;
  background-position: center 25%;

  @media (min-width: 768px) {
    height: 100%;
    background-position: center;
  }
}
</style>
