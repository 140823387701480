<template>
  <Menu
    :id="id"
    class="right-align-menu"
    ref="menu"
    popup
    :model="menuOptions"
  />
</template>

<script>
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores';
import { ROUTE_HOST_PROFILE, ROUTE_LOGIN } from '@/router/routes';

export default {
  props: {
    id: {
      type: String,
      default: 'profile-menu',
    },
  },
  data() {
    return {
      menuOptions: [
        {
          label: 'Profile',
          command: () => this.$router.push({ name: ROUTE_HOST_PROFILE }),
        },
        {
          label: 'Logout',
          command: () => this.logout(),
        },
      ],
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  methods: {
    toggle(event) {
      if (this.$refs.menu) {
        this.$refs.menu.toggle(event);
      }
    },
    logout() {
      this.authStore.logout();
      this.$router.push({
        name: ROUTE_LOGIN,
      });
    },
  },
};
</script>
