<template>
  <Message
    severity="success"
    :closable="false"
    :pt="{
      icon: {
        class: 'mt-1 text-xl align-self-start',
      },
      text: {
        class: 'font-normal',
      },
    }"
  >
    Your organization has already set up a payment method.
    Have questions in regards to payment or want
    to change your payment method? Contact
    <a
      class="text-primary"
      :href="mailTo"
    >
      {{ SUPPORT_EMAIL }}
      <i class="text-sm pi pi-external-link" />
    </a>
  </Message>
</template>

<script>
import { mapStores } from 'pinia';
import { useMyUserStore } from '@/stores';
import { SUPPORT_EMAIL } from '@/constants';
import { generateBodyText } from '@/utils/support';

export default {
  computed: {
    ...mapStores(useMyUserStore),
    mailTo() {
      const subject = 'Ekoz.ai Payment Question';

      const body = generateBodyText({
        userId: this.myUserStore.userId,
        url: window.location.href,
      });

      return `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;
    },
  },
  data() {
    return {
      SUPPORT_EMAIL,
    };
  },
};
</script>
