<template>
  <div >
    <Skeleton
        v-if="isLoading"
        height="74px"
      />
    <Card
      v-else-if="!isLoading && src"
      class="bg-gray-100"
      :pt="{
        body: {
          class: 'p-2'
        }
      }"
    >
      <template #content>
        <audio
          class="w-full"
          controls
          :src="src"
        />
        <!-- voiceprint score -->
        <template v-if="isScoreLoading">
          <Skeleton
            height="60px"
          />
        </template>
        <div
          v-else-if="!isScoreLoading && score !== null"
          class="px-4"
        >
          <div class="mb-3">
            <i
              class="pi pi-info-circle text-indigo-400 mr-1"
              @click="$refs.voiceprintHelperOverlay
                && $refs.voiceprintHelperOverlay.toggle($event)"
              @keydown="$refs.voiceprintHelperOverlay
                && $refs.voiceprintHelperOverlay.toggle($event)"
            />
            Voiceprint Eko score: <span class="font-bold">{{ scoreTextValue }}</span>
          </div>
          <OverlayPanel ref="voiceprintHelperOverlay">
            <div
              :style="{
                maxWidth: '300px',
              }"
            >
              <p>This score shows how similar your Voiceprint Eko is to your original
                voice samples.</p>
              <p>Fine tune your voice settings to increase the similarity score.</p>
            </div>
          </OverlayPanel>
          <MeterGroup
            :value="scoreMeterValue"
            :pt="{
              labelList: {
                class: 'hidden',
              }
            }"
          />
        </div>
      </template>
    </Card>
    <slot
      v-else-if="!isLoading && !src"
      name="noVoiceprint"
    >
      No Voiceprint Eko found.
    </slot>
  </div>
</template>

<script>
const SCORE_COLOR_GREAT = '#22c55e';
const SCORE_COLOR_GOOD = '#fa8e42';
const SCORE_COLOR_POOR = '#ff6259';

export default {
  props: {
    src: {
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    score: {
      validator: (prop) => typeof prop === 'number' || prop === null,
      default: null,
    },
    isScoreLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    scoreTextValue() {
      if (this.score <= 33) {
        return 'Poor';
      }

      if (this.score > 33 && this.score <= 66) {
        return 'Good';
      }

      return 'Great';
    },
    scoreMeterValue() {
      let color = SCORE_COLOR_GOOD;

      if (this.score <= 33) {
        color = SCORE_COLOR_POOR;
      } else if (this.score > 33 && this.score <= 66) {
        color = SCORE_COLOR_GOOD;
      } else {
        color = SCORE_COLOR_GREAT;
      }

      return [{
        color,
        value: Math.min(this.score, 100),
      }];
    },
  },
};
</script>
