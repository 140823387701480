import { LOCAL_STORAGE_USER_TOKEN_KEY } from '@/constants';
import { useAuthStore } from '@/stores';

export const compareLocalStorageTokenToStoreToken = () => {
  try {
    // only run method if document is becoming visible
    if (document.visibilityState !== 'visible') {
      return;
    }

    const localStorageToken = localStorage.getItem(LOCAL_STORAGE_USER_TOKEN_KEY) || '';

    const authStore = useAuthStore();
    const storeToken = authStore.token;

    if (storeToken !== localStorageToken && window.location && window.location.reload) {
      /* eslint-disable-next-line no-console */
      console.log('pinia store token does not match local storage token. Refreshing page...');
      window.location.reload();
    }
  } catch (error) {
    /* eslint-disable-next-line no-console */
    console.error('Error comparing pinia store token to local storage token');
  }
};
