<template>
  <div class="voiceprint-container">
    <div
      ref="formBody"
      class="form-container flex-grow-1"
    >
      <!-- Step 1 -->
      <Card v-if="activeStep === 1">
        <template #content>
          <WizardStepHeader
            :stepNumber="1"
            title="Setup Profile"
          />

          <Divider />

          <VeeForm
            v-slot="{ handleSubmit }"
            as="div"
            :validationSchema="stepOneSchema"
            @invalidSubmit="onInvalidSubmit"
          >
          <form
            id="voiceprint-wizard-form"
            @submit.prevent="handleSubmit($event, onSubmitStepOne)"
          >
            <div class="grid">
              <div class="col-6">
                <BaseDropdown
                  v-model="stepOneForm.accent"
                  fieldId="accent"
                  fieldName="accent"
                  fieldLabel="Accent"
                  placeholder="Choose an accent"
                  :options="ACCENT_OPTIONS"
                  filter
                >
                  <template #value="slotProps">
                    <div class="flex align-items-center">
                      {{ slotProps.value || slotProps.placeholder }}
                      <Chip
                        v-if="accentIsBeta"
                        class="ml-1 text-xs py-0 bg-indigo-100"
                        label="Beta"
                      />
                    </div>
                  </template>
                  <template #option="slotProps">
                    {{ slotProps.option.label }}
                    <Chip
                      v-if="slotProps.option.isBeta"
                      class="ml-2 text-xs py-0 bg-indigo-100"
                      label="Beta"
                    />
                  </template>
                </BaseDropdown>
              </div>
              <div class="col-6">
                <BaseDropdown
                  v-model="stepOneForm.gender"
                  fieldId="gender"
                  fieldName="gender"
                  fieldLabel="Gender"
                  placeholder="Choose a gender"
                  :options="[
                    { label: 'Male', value: 'Male'},
                    { label: 'Female', value: 'Female'},
                    { label: 'Neutral', value: 'Neutral'},
                  ]"
                />
              </div>
              <div class="col-12">
                <BaseTextarea
                  v-model="stepOneForm.description"
                  fieldId="publicBio"
                  fieldName="description"
                  fieldLabel="Host Professional Bio"
                  helperText="Write a professional bio about yourself.
                    Include things like work history, education, skills
                    and expertise. Such as something you might have on LinkedIn."
                  :characterLimit="PUBLIC_BIO_CHARACTER_LIMIT"
                />
              </div>

              <div class="col-12">
                <BaseTextarea
                  v-model="stepOneForm.personalBio"
                  fieldId="personalBio"
                  fieldName="personalBio"
                  fieldLabel="Host Personal Bio"
                  :characterLimit="PERSONAL_BIO_CHARACTER_LIMIT"
                >
                  <template #helperText>
                    <small id="personalBio-help">
                      <p>
                        Write a personal bio describing yourself.
                        We recommend including specific information
                        that you may relay in your show or on social
                        media that will be used to craft personalized ads.
                      </p>
                      <p>
                        For example, "I have three kids, a son and two daughters,
                        and have been married for 20 years. When not producing my show,
                        I love to work out and ski. My dog is a golden doodle named Georgia.
                      </p>
                    </small>
                  </template>
                </BaseTextarea>
              </div>
            </div>
          </form>
          </VeeForm>
        </template>
      </Card>

      <!-- Step 2 -->
       <Card v-else-if="activeStep === 2">
        <template #content>
          <WizardStepHeader
            :stepNumber="2"
            title="Add Voice Samples"
          />

          <Divider />

          <VeeForm
            v-slot="{ handleSubmit }"
            as="div"
            :validationSchema="stepTwoSchema"
            @invalidSubmit="onInvalidSubmit"
          >
            <form
              id="voiceprint-wizard-form"
              @submit.prevent="handleSubmit($event, onSubmitStepTwo)"
            >
              <BaseFileUpload
                v-model="stepTwoForm.selectedFiles"
                fieldId="selectedFiles"
                fieldName="selectedFiles"
                helperText="
                  We recommend including 1 to 3 samples that total to at
                  least 90 seconds to provide the most accurate result.
                  The samples should be as clear as possible with no background noise.
                  If available, use existing ads that you have recorded."
                accept="audio/mpeg"
                :multiple="true"
                dragText="Drag your pdf or docx file here"
              />
            </form>
          </VeeForm>
        </template>
       </Card>

      <!-- Step 3 -->
       <Card v-else-if="activeStep === 3">
        <template #content>
          <WizardStepHeader
            :stepNumber="3"
            title="Your Voiceprint"
          />

          <Divider />

          <p>
            This is an example ad based on your Voiceprint Eko.
            Adjust the settings below and click "Update" to fine-tune your Voiceprint Eko.
          </p>

          <form
            id="voiceprint-wizard-form"
            @submit.prevent="onSubmitStepThree"
          >
            <VoiceprintCard
              :isLoading="myUserStore.myVoiceprintIsLoading"
              :src="myUserStore.myVoiceprint"
              :score="myUserStore.myVoiceprintScore"
              :isScoreLoading="myUserStore.myVoiceprintScoreIsLoading"
            >
              <template #noVoiceprint>
                No Voiceprint Eko found.
              </template>
            </VoiceprintCard>

            <p class="text-right text-sm">
              Need help fine tuning? Contact
              <a
                class="text-primary"
                :href="mailTo"
              >
                {{ SUPPORT_EMAIL }}
                <i class="text-xs pi pi-external-link" />
              </a>
              our support.
            </p>
          </form>

          <Card class="bg-dark-grey mt-3 text-white">
            <template #title>
              Settings
            </template>
            <template #content>
              <Button
                class="w-full justify-content-center"
                label="Update"
                :loading="isUpdatingSettings"
                @click="onUpdateSettings"
              />
              <div class="grid">
                <div class="col-12 sm:col-6 pb-0 sm:pb-2">
                  <VoiceprintSlider
                    v-for="item in leftSliders"
                    :key="item.name"
                    v-model="stepThreeForm[item.formField]"
                    :name="item.name"
                    :formId="item.formId"
                    :tooltip="item.tooltip"
                    :labelUnit="item.labelUnit"
                    :min="item.min"
                    :max="item.max"
                    :step="item.step"
                    :transformFunction="item.transformFunction"
                  />
                </div>
                <div class="col-12 sm:col-6 pt-0 sm:pt-2">
                  <VoiceprintSlider
                    v-for="item in rightSliders"
                    :key="item.name"
                    v-model="stepThreeForm[item.formField]"
                    :name="item.name"
                    :formId="item.formId"
                    :tooltip="item.tooltip"
                    :labelUnit="item.labelUnit"
                    :min="item.min"
                    :max="item.max"
                    :step="item.step"
                    :transformFunction="item.transformFunction"
                  />
                </div>
              </div>
            </template>
          </Card>
        </template>
       </Card>
    </div>

    <div class="form-container flex justify-content-between flex-wrap py-4 row-gap-1">
      <Button
        text
        plain
        :label="cancelButtonLabel"
        :disabled="isSubmitting"
        @click="() => this.$emit('cancel', true)"
      />
      <div class="flex">
        <Button
          v-if="activeStep !== 1"
          text
          plain
          label="Previous"
          :disabled="isSubmitting"
          @click="onClickPrevious"
        />
        <Button
          class="ml-2"
          :label="nextButtonText"
          type="submit"
          form="voiceprint-wizard-form"
          :loading="isSubmitting"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useMyUserStore } from '@/stores';
import { object, string, array } from 'yup';
import WizardStepHeader from '@/components/wizardStepHeader';
import { INVALID_FORM_SUBMISSION_MESSAGE } from '@/utils/messages';
import { parseMessageFromError } from '@/utils/errors';
import * as api from '@/api';
import {
  ACCENT_OPTIONS,
  PERSONAL_BIO_CHARACTER_LIMIT,
  PUBLIC_BIO_CHARACTER_LIMIT,
  SUPPORT_EMAIL,
} from '@/constants';
import VoiceprintCard from '@/components/voiceprintCard';
import VoiceprintSlider from '@/components/voiceprintWizard/components/voiceprintSlider';
import { generateBodyText } from '@/utils/support';

const SLIDERS = [
  {
    name: 'Charisma',
    formId: 'slider-charisma',
    formField: 'voiceStability',
    tooltip: 'This determines the emotional range of the voice. Increasing the slider, adds a broader range of emotion.',
    labelUnit: '%',
  },
  {
    name: 'Vocal Clarity',
    formId: 'slider-vocal-clarity',
    formField: 'voiceSimilarityBoost',
    tooltip: 'This determines how close the voice should match to the original audio samples.',
    labelUnit: '%',
  },
  {
    name: 'Style Exaggeration',
    formId: 'slider-style-exaggeration',
    formField: 'styleExaggeration',
    tooltip: 'This exaggerates the style of the original audio samples. 0 means no exaggeration.',
    labelUnit: '%',
  },
  {
    name: 'Pitch',
    formId: 'slider-pitch-change',
    formField: 'pitchChange',
    min: 90,
    max: 110,
    step: 0.1,
    // transform values values of range 90 - 110 to -10 - 10
    transformFunction: (value) => Math.round((value - 100) * 100) / 100,
  },
  {
    name: 'Speed',
    formId: 'slider-speed-change',
    formField: 'speedChange',
    min: 0.9,
    max: 1.1,
    step: 0.01,
  },
];

export default {
  components: {
    WizardStepHeader,
    VoiceprintCard,
    VoiceprintSlider,
  },
  props: {
    cancelButtonLabel: {
      type: String,
      default: 'Cancel',
    },
  },
  computed: {
    ...mapStores(useMyUserStore),
    nextButtonText() {
      switch (this.activeStep) {
        case 3:
          return 'Approve and Save';
        default:
          return 'Next';
      }
    },
    accentIsBeta() {
      if (!this.stepOneForm.accent) return false;

      const chosenAccent = ACCENT_OPTIONS.find((item) => item.value === this.stepOneForm.accent);

      return chosenAccent && chosenAccent.isBeta === true;
    },
    mailTo() {
      const subject = 'I need help with my Voiceprint Eko';

      const body = generateBodyText({
        userId: this.myUserStore.userId,
        url: window.location.href,
      });

      return `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;
    },
  },
  watch: {
    activeStep() {
      if (this.$refs.formBody) {
        this.$refs.formBody.scrollIntoView({
          behavior: 'smooth',
        });
      }
    },
  },
  data() {
    return {
      PUBLIC_BIO_CHARACTER_LIMIT,
      PERSONAL_BIO_CHARACTER_LIMIT,
      ACCENT_OPTIONS,
      SUPPORT_EMAIL,
      leftSliders: SLIDERS.slice(0, 3),
      rightSliders: SLIDERS.slice(3),
      isSubmitting: false,
      activeStep: 1,
      stepOneSchema: object({
        accent: string().required('Accent is required'),
        gender: string().required('Gender is required'),
        description: string().nullable().max(PUBLIC_BIO_CHARACTER_LIMIT, `Host professional bio can be a max of ${PUBLIC_BIO_CHARACTER_LIMIT} characters.`),
        personalBio: string().nullable().max(PERSONAL_BIO_CHARACTER_LIMIT, `Host personal bio can be a max of ${PERSONAL_BIO_CHARACTER_LIMIT} characters.`),
      }),
      stepOneForm: {
        accent: null,
        gender: null,
        description: '',
        personalBio: '',
      },
      stepTwoSchema: object({
        selectedFiles: array().min(1, 'Must upload at least 1 file').required(),
      }),
      stepTwoForm: {
        selectedFiles: [],
      },
      stepThreeForm: {
        voiceStability: null,
        voiceSimilarityBoost: null,
        styleExaggeration: null,
        pitchChange: null,
        speedChange: null,
      },
      // used in case user goes to step 3, back to step 2, then back to step 3
      filesUploaded: [],
      isUpdatingSettings: false,
    };
  },
  mounted() {
    this.stepOneForm.accent = this.myUserStore.voiceAccent;
    this.stepOneForm.gender = this.myUserStore.voiceGender;
    this.stepOneForm.description = this.myUserStore.myUser
      && this.myUserStore.myUser.description
      ? this.myUserStore.myUser.description
      : '';
    this.stepOneForm.personalBio = this.myUserStore.myUser
    && this.myUserStore.myUser.personal_bio
      ? this.myUserStore.myUser.personal_bio
      : '';

    this.stepThreeForm.voiceStability = Math.round(
      (1 - this.myUserStore.voiceStability) * 100,
    );
    this.stepThreeForm.voiceSimilarityBoost = Math.round(
      this.myUserStore.voiceSimilarityBoost * 100,
    );
    this.stepThreeForm.styleExaggeration = Math.round(
      this.myUserStore.voiceStyleExaggeration * 100,
    );
    this.stepThreeForm.pitchChange = this.myUserStore.voicePitchChange;
    this.stepThreeForm.speedChange = this.myUserStore.voiceSpeedChange;

    if (this.myUserStore.voiceId && !this.myUserStore.myVoiceprint) {
      this.myUserStore.getMyVoiceprint()
        .catch((error) => {
          const message = parseMessageFromError(error, 'Error generating Voiceprint Eko');

          this.$toast.add({
            severity: 'error',
            detail: message,
          });
        });
    }
  },
  methods: {
    onInvalidSubmit() {
      this.$toast.add({
        severity: 'warn',
        detail: INVALID_FORM_SUBMISSION_MESSAGE,
      });
    },
    onClickPrevious() {
      switch (this.activeStep) {
        case 1:
          this.$emit('cancel', true);
          break;
        case 2:
          this.activeStep = 1;
          break;
        case 3:
          this.activeStep = 2;
          break;
        default:
          // do nothing
      }
    },
    async onSubmitStepOne() {
      try {
        this.isSubmitting = true;

        await api.updateUser({
          userId: this.myUserStore.userId,
          description: this.stepOneForm.description,
          personalBio: this.stepOneForm.personalBio,
        });
        await this.myUserStore.getMyUser();

        this.activeStep = 2;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error updating user.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isSubmitting = false;
      }
    },
    async onSubmitStepTwo() {
      try {
        this.isSubmitting = true;

        const newFiles = this.stepTwoForm.selectedFiles
          .filter((item) => !this.filesUploaded.includes(item));

        // if user has a voiceprint and is uploaded new files
        //  upload files
        //  refresh voice sample files
        //  set user voice settings
        //  read current voice settings
        //  update user voiceprint
        //  refresh my user data
        if (this.myUserStore.voiceId && newFiles.length > 0) {
          await api.createAudioSamples(this.myUserStore.userId, newFiles);
          await this.myUserStore.getMyVoiceSamples();

          await api.updateUserVoiceSettings({
            userId: this.myUserStore.userId,
            voiceStability: Math.round((1 - (this.stepThreeForm.voiceStability / 100)) * 100) / 100,
            voiceSimilarityBoost: this.stepThreeForm.voiceSimilarityBoost / 100,
            styleExaggeration: this.stepThreeForm.styleExaggeration / 100,
            pitchChange: this.stepThreeForm.pitchChange,
            speedChange: this.stepThreeForm.speedChange,
          });
          await this.myUserStore.getMyVoiceSettings(this.myUserStore.userId);
          await api.updateUserVoicePrint({
            userId: this.myUserStore.userId,
            sampleIds: this.myUserStore.myVoiceSamples.map((item) => item.id),
            accent: this.stepOneForm.accent,
            gender: this.stepOneForm.gender,
          });
          await this.myUserStore.getMyVoiceprint();
          this.getMyVoiceprintScore();
          await this.myUserStore.getMyUser();
        } else if (!this.myUserStore.voiceId && newFiles.length > 0) {
          // if user does not have a voiceprint, create one
          //  set user voice settings
          //  create voiceprint with files
          //  refresh voice sample files
          //  refresh my user data
          await api.updateUserVoiceSettings({
            userId: this.myUserStore.userId,
            voiceStability: Math.round((1 - (this.stepThreeForm.voiceStability / 100)) * 100) / 100,
            voiceSimilarityBoost: this.stepThreeForm.voiceSimilarityBoost / 100,
            styleExaggeration: this.stepThreeForm.styleExaggeration / 100,
            pitchChange: this.stepThreeForm.pitchChange,
            speedChange: this.stepThreeForm.speedChange,
          });
          await api.createUserVoicePrint({
            userId: this.myUserStore.userId,
            files: newFiles,
            accent: this.stepOneForm.accent,
            gender: this.stepOneForm.gender,
          });
          await this.myUserStore.getMyVoiceSamples();
          await this.myUserStore.getMyVoiceprint();
          this.getMyVoiceprintScore();
          await this.myUserStore.getMyUser();
        }

        this.filesUploaded = [...this.filesUploaded, ...newFiles];

        this.activeStep = 3;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error uploading files.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isSubmitting = false;
      }
    },
    async getMyVoiceprintScore() {
      try {
        await this.myUserStore.getMyVoiceprintScore();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error loading Voiceprint Eko score.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async onUpdateSettings() {
      try {
        this.isUpdatingSettings = true;

        await api.updateUserVoiceSettings({
          userId: this.myUserStore.userId,
          voiceStability: Math.round((1 - (this.stepThreeForm.voiceStability / 100)) * 100) / 100,
          voiceSimilarityBoost: this.stepThreeForm.voiceSimilarityBoost / 100,
          styleExaggeration: this.stepThreeForm.styleExaggeration / 100,
          pitchChange: this.stepThreeForm.pitchChange,
          speedChange: this.stepThreeForm.speedChange,
        });
        if (this.myUserStore.myVoiceprint) {
          // await api.updateUserVoicePrint({
          //   userId: this.myUserStore.userId,
          //   sampleIds: this.myUserStore.myVoiceSamples.map((item) => item.id),
          //   accent: this.stepOneForm.accent,
          //   gender: this.stepOneForm.gender,
          // });
          await this.myUserStore.getMyVoiceprint();
          this.getMyVoiceprintScore();
        }
        // refresh voice settings data
        await this.myUserStore.getMyUser();
        await this.myUserStore.getMyVoiceSettings();

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully updated voice settings',
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error updating settings.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isUpdatingSettings = false;
      }
    },
    onSubmitStepThree() {
      this.$toast.add({
        severity: 'success',
        detail: 'Voiceprint Eko approved and saved.',
      });

      this.$emit('complete', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  max-width: 800px;
  width: 100%;
}

.voiceprint-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
