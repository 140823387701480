import ekozAxios from './instance/axios';

export const createAdvertiser = ({
  organizationId,
  name,
  iabCodes,
}) => ekozAxios.request({
  method: 'post',
  url: '/ssp/new_advertiser.php',
  data: {
    organization_id: organizationId,
    name,
    iab_codes: iabCodes,
  },
});

export const updateAdvertiserCategories = ({
  organizationId,
  iabCodes,
}) => ekozAxios.request({
  method: 'post',
  url: '/ssp/update_advertiser_categories.php',
  data: {
    organization_id: organizationId,
    iab_codes: iabCodes,
  },
});

export const readAdvertiserCategories = ({
  organizationId,
}) => ekozAxios.request({
  method: 'get',
  url: '/ssp/get_advertiser_categories.php',
  params: {
    organization_id: organizationId,
  },
});
