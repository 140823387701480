import { defineStore } from 'pinia';
import * as api from '@/api';

export const useCampaignsStore = defineStore('campaigns', {
  state: () => ({
    campaigns: [],
    campaignsAreLoading: false,
  }),
  getters: {
    getCampaignById: (state) => (
      (campaignId) => state.campaigns.find((item) => Number(item.id) === Number(campaignId))
    ),
    getCampaignsByOrganizationId: (state) => (
      (organizationId) => state.campaigns
        .filter((item) => Number(item.org_id) === Number(organizationId))
    ),
  },
  actions: {
    async getCampaigns({
      organizationId,
    } = {}) {
      try {
        this.campaignsAreLoading = true;

        const res = await api.readCampaigns({
          organizationId,
        });

        this.campaigns = res.data.map((item) => ({
          ...item,
          bid_cap: !Number.isNaN(Number(item.bid_cap))
            ? Number(item.bid_cap)
            : item.bid_cap,
        }));
      } finally {
        this.campaignsAreLoading = false;
      }
    },
  },
});
